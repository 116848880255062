<template>
  <div v-if="section">
    <b-tabs>
      <b-tab title="Settings" active>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex mb-3">
              <label class="mr-4">Show Name Input</label>
              <b-form-checkbox v-model="section.ShowName" switch></b-form-checkbox>
            </div>
          </div>
          <template v-if="section.ShowName">
            <div class="col-md-12">
              <div class="form-group">
                <label>Email placeholder</label>
                <input v-model="section.NamePlaceholder" type="text" class="form-control" placeholder="Your FirstName" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex mb-3 coupon-input-checkbox">
                <b-form-checkbox v-model="section.NameRequired" name="emailRequired">
                  Required
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex mb-3">
              <label class="mr-4">Show Email Input</label>
              <b-form-checkbox v-model="section.ShowEmail" switch></b-form-checkbox>
            </div>
          </div>
          <template v-if="section.ShowEmail">
            <div class="col-md-12">
              <div class="form-group">
                <label>Email placeholder</label>
                <input v-model="section.EmailPlaceholder" type="text" class="form-control" placeholder="Your Email" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex mb-3 coupon-input-checkbox">
                <b-form-checkbox v-model="section.EmailRequired" name="emailRequired">
                  Required
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex mb-3">
              <label class="mr-4">Show Phone Input</label>
              <b-form-checkbox v-model="section.ShowPhone" switch></b-form-checkbox>
            </div>
          </div>
          <template v-if="section.ShowPhone">
            <div class="col-md-12">
              <div class="form-group">
                <label>Phone placeholder</label>
                <input v-model="section.PhonePlaceholder" type="text" class="form-control" placeholder="Your Phone" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex mb-3 coupon-input-checkbox">
                <b-form-checkbox v-model="section.PhoneRequired" name="phoneRequired">
                  Required
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </div>
      </b-tab>
      <b-tab title="Styling">
        <div class="row">
          <div class="col-md-6">
            <ColorInput v-model="section.BackgroundColor" label="Background" />
          </div>
          <div class="col-md-6">
            <ColorInput v-model="section.BorderColor" label="Border color" />
          </div>
          <div class="col-md-6">
            <NumberInput v-model="section.Roudness" label="Corner roudness" />
          </div>
          <div class="col-md-6">
            <ColorInput v-model="section.Color" label="Color" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ColorInput from './ColorInput'
import NumberInput from './NumberInput'

export default {
  components: {
    ColorInput,
    NumberInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style lang="scss">
.coupon-input-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>
